* {
  margin: 0;
  padding: 0;
  -ms-overflow-style: none;
  box-sizing: border-box;
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  background-color: #eff3f9;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
  font-size: 1rem;
}

a {
  display: block;
  text-decoration: none;
}

svg {
  flex-shrink: 0;
}

button {
  font-size: 1rem;
}

input,
select {
  font-size: 0.9rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(37, 37, 37, 0.5);
}

.with-financial-model-selector .align-with-selector {
  margin-top: -2.74rem;
}

::-webkit-scrollbar {
  display: none;
}